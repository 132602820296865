@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';



.Section.Welcome {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: $dims_padding-large 0 0;

    background:url(../../assets/banner.jpg),  rgba(color.$white, .6) !important;
    background-size: cover !important;
    background-position: center center !important;
    background-blend-mode: screen;

    .content {
        .Anchor {
            top: 5vh;
        }

        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 $dims_padding-medium;
        margin: auto;
        z-index: 1;
        flex: 1 0 auto;

        @include respond-to(tablet) {
            padding: 0 $dims_padding-large;
        }
    }

    h2, p {
        width: 80%;

        @include respond-to(tablet) {
            width: 70%;
        }
    }

    h2 {
        margin: auto 0;
        color: $color_brand--secondary;

        font-family: $font_family-primary;
        font-size: $font_size-largest * .75;
        font-weight: 600;
        line-height: 1.2em;

        @include respond-to(tablet) {
            font-size: $font_size-largest;
        }
    }

    h3 {
        display: none;
    }

    footer {
        display: none;
        margin: 0;
        margin-bottom: $dims_padding-medium;
        flex: 0 1 auto;

        top: 2em;
        font-size: $font_size-medium;
        text-align: center;

        background: none;
        cursor: pointer;
        opacity: 1;
        pointer-events: all;
        transition-delay: 1s;
        transition: opacity animation.$speed, transform animation.$speed;
        transform: translateY(0em);

        @include respond-to(tablet) {
            display: block;
        }

        &.hidden {
            opacity: 0;
            transform: translateY(-1em);
            transition-delay: 0s;
            pointer-events: none;
        }

        p {
            font-size: $font_size-large;
            color: $color_brand;
            margin: $dims_padding-small auto;
            font-weight: 700;
        }

        &:hover {
            svg {
                transform: translateY(.1em);
            }
        }

        svg {
            $size: $font_size-larger;

            width: $size;
            height: $size;
            margin: auto;
            color: $color_brand--secondary;
            transform: translateY(0em);
            transition: transform animation.$speed * .5;
        }
    }

}

.Contact {

    h3 {
        color: $color_brand--secondary;
    }

    h2 {
        color: $color_brand;
    }

    .row {
        display: block;

        @include respond-to(tablet) {
            display: flex;
        }
    }

    .ContactForm {
        flex: 1 0 40%;
        margin-top: $dims_padding-medium;
        margin-bottom: $dims_padding-large;

        @include respond-to(tablet) {
            margin-top: 0;
        }
    }

    .engagement-content {
        flex: 1 0 50%;
    }

    .engagement-animation {
        position: absolute;
        top: 0;
        left: 0;
        display: none;

        width: 100%;
        height: 100%;

        pointer-events: none;

        @include respond-to(tablet) {
            display: block;
        }

        .Callout {
            position: absolute;
            right: 5%;
            bottom: 30%;
            background: $color_yellow;
            transform: scale(0);

            &:before {
                top: -.5em;
                bottom: unset;
                right: 1.5em;
                border-top-color: $color_yellow;
                transform: rotate(180deg);
            }
        }

        .Pin {
            position: absolute;
            bottom: 60%;
            right: 10%;
            opacity: 0;

            img {
                background: #F27D77;
            }
            span {
                background: #FFD8D6;
            }
            &:after {
                background: #F27D77;
            }
        }

        &.engage {
            .Callout {
                animation: popIn .7s forwards;
            }

            .Pin {
                animation: dropIn .7s forwards;
                animation-delay: .5s;
            }
        }
    }

}

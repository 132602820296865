.Process {
    $phase1: #F1D54D;
    $phase2: #98CBEC;
    $phase3: #FAA35D;
    $phase4: #CEDC60;

    h3 {
        color: $color_light-blue;
    }

    h2 {
        color: $color_dark-blue;
    }

    .content > p {
        font-size: $font_size-medium;
    }


    .timeline {
        display: flex;
        flex-direction: column;

        margin: $dims_padding-medium 0;

        .block {
            flex: 1;

            margin-top: $dims_padding-small;
            padding: $dims_padding-small;

            text-align: center;
            font-size: $font_size-medium;

            border: 2px solid transparent;
            border-radius: $box_radius-medium;
            cursor: pointer;
            transition: border-color .4s;

            p {
                margin: 0;
                padding: 0;

                span {
                    display: inline;

                    + span {
                        margin-left: .4em;
                    }
                }
            }

            &.active {
                border-color: $color_brand;
            }

            &:nth-of-type(1) {
                width: 30%;
                left: 0;
                background: $phase1;
            }

            &:nth-of-type(2) {
                width: 30%;
                left: 22%;
                background: $phase2;
            }

            &:nth-of-type(3) {
                width: 30%;
                left: 45%;
                background: $phase3;
            }

            &:nth-of-type(4) {
                width: 30%;
                left: 70%;
                background: $phase4;
            }

            span.will-hide {
                display: none;

                @include respond-to(tablet) {
                    display: inline-block;
                }
            }
        }
    }

    .phases {
        display: flex;
        flex-direction: column;

        > li {
            flex: 1 0 auto;

            height: ((($dims_padding-small * 1.5) * 2.15) + $font_size-medium);
            padding: $dims_padding-small;

            background: $color_bg-light;
            border-radius: $box_radius-medium;
            box-shadow: $box_shadow;
            cursor: pointer;
            overflow: hidden;
            transition: height .2s;

            @include respond-to(tablet) {
                padding: $dims_padding-small * 1.5;
            }

            &:nth-of-type(1) header {
                border-color: $phase1;
            }

            &:nth-of-type(2) header {
                border-color: $phase2;
            }

            &:nth-of-type(3) header {
                border-color: $phase3;
            }

            &:nth-of-type(4) header {
                border-color: $phase4;
            }

            header {
                display: flex;
                padding-bottom: $dims_padding-small;

                @include respond-to(tablet) {
                    padding-bottom: $dims_padding-small * 1.5;
                }

                border-bottom: 5px solid $color_bg-medium;

                button {
                    display: inline-block;

                    height: $font_size-large;
                    width: $font_size-large;
                    margin: 0;
                    padding: 0;

                    background: none;
                    border: none;
                    transform: rotate(45deg);
                    transition: transform .3s;
                    cursor: pointer;
                }

                > span {
                    color: rgba($color_font-dark, .4);
                    font-weight: 500;

                    @include respond-to(tablet) {
                        font-size: $font_size-medium;
                    }
                }

                * + * {
                    margin-left: $dims_padding-small;
                }
            }

            p:first-of-type {
                margin-top: 0;
            }

            h4 {
                display: inline-block;

                margin-top: 0;
                margin-bottom: 0;

                font-size: $font_size-small;
                font-weight: 500;

                @include respond-to(tablet) {
                    font-size: $font_size-medium;
                }
            }

            + li {
                margin-top: $dims_padding-small * 1.5;
            }

            .Henshu__EditableRichText {
                padding: $dims_padding-small * 2 $dims_padding-small * 1.5;

                line-height: 1.4em;

                ul {
                    margin-left: $dims_padding-medium;
                }
            }

            &.active {
                height: auto;
                transition: height .2s;
                cursor: initial;

                header button {
                    transform: rotate(0deg);
                }
            }
        }
    }
}


.Modal {
    header {
        background: $color_brand--secondary--light !important;

        h3 {
            color: $color_brand--secondary !important;
        }

        p {
            display: none;
        }
    }
}

.App {
    .Anchor {
        position: absolute;
        top: 0px;

        @include respond-to(desktop) {
            top: -120px;

            &#welcome {
                top: 0px;
            }
        }
    }

    .ReactMobilized {
        $speed: .2s;
        $scale: .75;

        &.mobile {
            &.hamburgered {
                .ReactMobilized__Header {
                    box-shadow: none;
                }

                .ReactMobilized__App:after {
                    height: calc(100vh - 90px);
                    box-shadow: inset $box_shadow, 0 0 0 1000px $color_bg-light;
                }
            }

            .ReactMobilized__AnchorList {
                text-align: center;

                li {
                    width: max-content;
                    margin: auto;
                    margin-top: 1rem;
                }
            }
        }

        &.mobile, &__Header.shrink {
            .ReactMobilized__Header {
                &-content {
                    padding: $dims_padding-small $dims_padding-medium
                }

                &-logo {
                    height: $font_size-larger;

                    h1 {
                        font-size: $font_size-medium;
                    }
                }
            }
        }

        &:not(.mobile) .ReactMobilized__Header.shrink {
            .ReactMobilized__AnchorList a, .LanguageSelect {
                font-size: $font_size-small * $scale;
                padding: (($dims_padding-small * .66) * $scale) $dims_padding-small;
            }
        }

        &__banner {
            display: flex;
            background: $color_brand--light;

            a {
                color: $color_font-light !important;
                font-weight: 500;
                font-size: $font_size-small;
                font-family: $font_family-primary;
                line-height: 1.5em;
            }

            button {
                display: none !important;
            }
        }

        &__Header {
            box-shadow: $box_shadow;
            background: $color_brand--secondary--light;

            &-content {
                align-items: center;
                padding: $dims_padding-medium / 2 $dims_padding-medium;
                transition: padding $speed;
            }

            &-logo {
                //@extend .logo;
                display: flex;
                align-items: center;

                height: $font_size-largest;
                transition: height $speed;

                img {
                    height: 100%;
                    margin-right: $dims_padding-small;
                }

                h1 {
                    margin: auto 0;
                    font-size: $font_size-large;
                    color: $color_brand--secondary;
                    transition: font-size $speed;
                }
            }
        }

        &__AnchorList {
            ul {
                margin: 0;
            }

            .anchor-item {
                border: 2px solid transparent;
                border-radius: $box_radius-large;
                transition: border-color .5s;

                &:not(.active):hover {
                    border-color: rgba($color_brand--secondary, .4);
                }

                &.active {
                    border-color: $color_brand--secondary;
                }

                + .anchor-item {
                    margin-left: 1rem;
                }
            }

            a {
                display: block;
                padding: $dims_padding-small * .66 $dims_padding-small * 1.33;

                color: $color_brand--secondary;
                font-size: $font_size-small;

                transition: font-size $speed, padding $speed;
            }
        }

        &__hamburger {
            right: $dims_padding-medium;
            background: none;

            &.active {
                width: 3.125rem;
                height: 3.125rem;
            }

            &:before, &:after, *:before, *:after {
                background: $color_brand--secondary;
            }
        }
    }

    .ReactModal {
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s;

        &.active {
            opacity: 1;
            pointer-events: all;

            .ReactModal__window {
                top: 50%;
            }
        }

        &__close {
            z-index: 1;
            top: $dims_padding-small;
            right: $dims_padding-small;

            height: 1.875rem;
            width: 1.875rem;

            background: url(./assets/x-circle.svg) 100%;
            border: none;

            cursor: pointer;
            opacity: 1;
            transition: opacity .15s;

            &:hover{
                opacity: .6;
            }
        }

        &__window {
            top: 55%;
            display: flex;
            flex-direction: column;

            width: 90vw;
            max-height: 95vh;
            padding: 0;

            border-radius: $box_radius-medium;
            box-shadow: $box_shadow;
            transition: top .4s;

            @include respond-to(tablet) {
                width: 70vw;
            }

            iframe {
                width: 100%;
                height: 70vh;
            }

        }

        &__content {
            flex: 1 1 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }

        header {
            padding: $dims_padding-small $dims_padding-medium;

            font-size: $font_size-medium;
            font-weight: 600;
            text-align: center;
        }

        .modal-body {
            padding: $dims_padding-small $dims_padding-small * 1.5 $dims_padding-small * 1.5;

            overflow-y: auto;

            div {
                font-size: $font_size-medium;
                padding-bottom: $dims_padding-medium;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }

}
